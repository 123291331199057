<template>
    
    <div class="content-wrapper overflow-visible">
        <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.query.regId" />

        <div class="content">
            <b-form @submit.prevent="handleSubmit(doSubmit())">
            <div class="card">
                <div class="card-header bg-white" style="border-bottom: 1px solid rgba(0,0,0,.125);">
                    <h6 class="card-title font-weight-semibold">Kajian Awal Perawat</h6>
                </div>
                <b-card-body>
                    <VStepTab :step.sync="stepTab" :last="Steps.length" :config="stepValidate">
                        <template slot="link">
                            <template v-for="(v,k) in Steps">
                                <VStepLink :step.sync="stepTab" :option="k + 1" :key="k" :clickable="(k+1) < stepTab">
                                    {{ v.subtitle }}
                                </VStepLink>
                            </template>
                        </template>
                        <template slot="content">
                            <template v-for="(v,k) in Steps">
                                <VStepContent :step.sync="stepTab" :option="k+1" :key="k">
                                    <template slot="title">
                                        {{ v.subtitle }}
                                    </template>
                                    <template slot="fieldset">
                                        <div>
                                            <validation-observer
                                                ref="VFormAssesmentAwal"
                                            >
                                                <AssesmentAwal v-if="stepTab == 1" />
                                            </validation-observer>
                                            
                                            <validation-observer
                                                ref="VFormKebutuhanPasien"
                                            >
                                                <KebutuhanPasien v-if="stepTab == 2" />
                                            </validation-observer>

                                            <validation-observer
                                                ref="VFormKajianNyeri"
                                            >
                                                <KajianNyeri v-if="stepTab == 3" />
                                            </validation-observer>

                                            <validation-observer
                                                ref="VFormResikoJatuh"
                                            >
                                                <ResikoJatuh v-if="stepTab == 4" />
                                            </validation-observer>
                                            
                                            <validation-observer
                                                ref="VFormAsuhanKeperawatan"
                                            >
                                                <AsuhanKeperawatan v-if="stepTab == 5" />
                                            </validation-observer>

                                        </div>
                                    </template>
                                </VStepContent>
                            </template>
                        </template>
                        <template slot="action">
                            <ul role="menu" aria-label="Pagination">
                                
                                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>

                                <li :class="(stepTab==1?'disabled':'')" v-bind:aria-disabled="(stepTab==1?true:false)" @click="doPrev"><a href="javascript:" :class="'btn btn-light '+(stepTab==1?'disabled':'')" role="menuitem"><i class="icon-arrow-left13 mr-2"></i> Previous</a></li>

                                <li aria-hidden="false" aria-disabled="false" @click="doNext"><a href="javascript:" :class="'btn btn-primary '+(stepTab!=stepLast?'':'disabled')" role="menuitem">Next <i class="icon-arrow-right14 ml-2"></i></a></li>
                                

                                <li v-if="stepTab == stepLast" aria-hidden="false" aria-disabled="false"><button @click="doSubmit()"  type="button" class="btn btn-primary" role="menuitem">Submit<i class="icon-checkmark3 ml-2"></i></button></li>
                            </ul>
                        </template>
                    </VStepTab> 
                </b-card-body>
            </div>
            </b-form>
        </div>
    </div>

</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'


import VStepTab from '@/components/VStep/VStepTab.vue'
import VStepLink from '@/components/VStep/VStepLink.vue'
import VStepContent from '@/components/VStep/VStepContent.vue'

import AssesmentAwal from './AssesmentAwal.vue'
import KebutuhanPasien from './KebutuhanPasien.vue'
import KajianNyeri from './KajianNyeri.vue'
import ResikoJatuh from './ResikoJatuh.vue'
import AsuhanKeperawatan from './AsuhanKeperawatan.vue'

import PatientInfo from '@/components/Ranap/PatientInfo.vue'

export default {
    extends: GlobalVue,
    components: {
        VStepTab, VStepLink, VStepContent, AssesmentAwal, KebutuhanPasien, KajianNyeri, ResikoJatuh, AsuhanKeperawatan,PatientInfo
    },
    data(){
        return {
            stepTab: 1,
            stepLast: 5,
            last_page: 1,
            rowReg: {},
            Steps: [
                {
                    icon: 'create',
                    name: 'assesmen-awal',
                    subtitle: 'Assesmen Awal',
                    component: AssesmentAwal,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'kebutuhan-pasien',
                    subtitle: 'Kebutuhan Pasien',
                    component: KebutuhanPasien,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'kajian-nyeri',
                    subtitle: 'Kajian Nyeri',
                    component: KajianNyeri,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'resiko-jatuh',
                    subtitle: 'Resiko Jatuh',
                    component: ResikoJatuh,
                    completed: false
                },  
                {
                    icon: 'create',
                    name: 'asuhan-keperawatan',
                    subtitle: 'Asuhan Keperawatan',
                    component: AsuhanKeperawatan,
                    completed: false
                }
            ],
            mAlergi: [],
            mKesadaran: [],
            mPekerjaan: [],
            mPendidikan: [],
            mAgama: [],
            
            diagnosa: [],
            mSDKI: [],
            
            dataHasilRadiologi: [],
            dataHasilLab: [],
            dataHasilRadiologiDokter: [],
            dataHasilLabDokter: [],
            
            patientData: {},
            loading: {
                patientInfo: false,
            }
        }
    },
    computed: {
        stepValidate(){
            return {
                disabled:false,
                validate:true,
                refs:this.$children
            }
        },
        isKosongChecklistTindakan(){
            return this.row.arankrj_bed_cek_naik_turun != "Y" && this.row.arankrj_bed_cek_aman_saat_naik != "Y" && this.row.arankrj_bed_cek_saat_mobilitas != "Y" && this.row.arankrj_bed_cek_aman_saat_naik != "Y" && this.row.arankrj_bed_cek_saat_mobilitas != "Y" && this.row.arankrj_bed_cek_roda != "Y" && this.row.arankrj_bed_cek_rem != "Y" && this.row.arankrj_bed_cek_mekanik != "Y" && this.row.arankrj_bed_cek_meja_terkunci != "Y" && this.row.arankrj_bed_cek_meja_didinding != "Y" && this.row.arankrj_tiang_cek_naik_turun != "Y" && this.row.arankrj_tiang_cek_stabil != "Y" && this.row.arankrj_tiang_cek_berputar != "Y" && this.row.arankrj_tumpuan_proteksi != "Y" && this.row.arankrj_tumpuan_stabil != "Y" && this.row.arankrj_tumpuan_tidak_licin != "Y" && this.row.arankrj_bel_lampu_kamar != "Y" && this.row.arankrj_bel_alarm != "Y" && this.row.arankrj_bel_nomor_kamar != "Y" && this.row.arankrj_bel_sinyal_kamar != "Y" && this.row.arankrj_bel_mudah_diraih != "Y" && this.row.arankrj_bel_tempat_tidur != "Y" && this.row.arankrj_walker_ujung_karet != "Y" && this.row.arankrj_walker_stabil != "Y" && this.row.arankrj_toilet_berputar != "Y" && this.row.arankrj_toilet_stabil != "Y" && this.row.arankrj_rem != "Y" && this.row.arankrj_kursi_tinggi_sesuai != "Y" && this.row.arankrj_kursi_roda_berputar != "Y" && this.row.arankrj_kursi_rem_dioperasikan != "Y" && this.row.arankrj_kursi_rem_aman != "Y" && this.row.arankrj_kursi_rem_pengaman != "Y" && this.row.arankrj_kursi_kaki_dapat_dilipat != "Y" && this.row.arankrj_kursi_kaki_derajat_kemiringan != "Y" && this.row.arankrj_kursi_kaki_posisi_aman != "Y"
        }
    },
    methods: {  
        autofill(e,to){
            this.row[to] = e
        },
        inputData: _.debounce(function (field){
            setTimeout(()=>{
                if(field=='response_mata'){
                    this.row.arankkp_tingkat_kesadaran_mata = this.row.aranktk_response_mata
                }
                if(field=='response_motorik'){
                    this.row.arankkp_tingkat_kesadaran_motorik = this.row.aranktk_response_motorik
                }
                if(field=='response_verbal'){
                    this.row.arankkp_tingkat_kesadaran_verbal = this.row.aranktk_response_verbal
                }
            },100)
            console.log("for realtime")
        },500),

        apiGet(params = {}, page = 1){
            if(!this.pageSlug || !this.$route.query.regId){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }

            if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
            this.data.data = false
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            let url = this.$route.params.rmNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.rmNo : this.modulePage+'/'+this.$route.params.pageSlug
            
            Gen.apiRest(
                "/get/"+url, 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                this.loadingOverlay = false
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.pageNow = page
                this.last_page = this.data.last_page
                
                if(!this.isFound){
                    this.$router.push({name : 'Dashboard'}).catch(()=>{})
                }
            }).catch(()=>{
                this.loadingOverlay = false
            })

            // master
            if(this.$route.params.rmNo){
                Gen.apiRest(
                    "/get/"+url+'?master=1', 
                    {
                    params: Object.assign({page: page}, paramsQuery, params.query||{})
                    }
                ).then(res=>{
                    console.log(res.data)
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            }
            
        },
       
        getPatientInfo() {
            this.$set(this.loading, 'patientInfo', true)
            Gen.apiRest(
            "/do/" + 'RanapPasienPerawatan', {
                data: {
                type: "get-data-pasien",
                id_registrasi: this.$route.query.regId
                }
            },
            "POST"
            ).then(resp => {
            this.$set(this, 'patientData', resp.data.data)

            this.$set(this.loading, 'patientInfo', false)
            })
        },


        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },
        
        autoSave: _.debounce(function (data) {
            data.type = 'auto-save'
            data.aranrm_last_step = this.row.aranrm_last_step

            if(!data.isEdit){
                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data:data}, 
                    "POST"
                )
            }
        },1000),
        
        autoSaveDiagnosa: _.debounce(function (data) {
            let dataPost = {
                type : 'auto-save-diagnosa',
                data : data
            }  
            if(!this.row.isEdit){
                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data:dataPost}, 
                    "POST"
                )
            }
        },1000),  


        doPrev(){
            if(this.stepTab !== 1){
                this.stepTab = this.stepTab -1
                this.row.aranrm_last_step = this.stepTab
                this.autoSave(this.row)
                window.scrollTo(0,0)
            }
        },
        
        doNext(){
            if(this.stepTab < this.stepLast){
                if(this.stepTab == 1){
                    this.$refs['VFormAssesmentAwal'][0].validate().then(success=>{
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.aranrm_last_step = this.stepTab
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }
                else if(this.stepTab == 2){
                    if(this.row.arankkp_pengkajian_restrain == "Y"){
                        if(!this.row.arankkp_ttv_tekanan_darah_min || !this.row.arankkp_ttv_tekanan_darah_max || !this.row.arankkp_ttv_nadi || !this.row.arankkp_ttv_label || !this.row.arankkp_ttv_pernafasan || !this.row.arankkp_ttv_suhu || !this.row.arankkp_tingkat_kesadaran_mata || !this.row.arankkp_tingkat_kesadaran_verbal || !this.row.arankkp_tingkat_kesadaran_motorik){
                            return this.$swal({
                                icon: 'error',
                                text: 'Terdapat Kajian Restrain, Kajian Restrain Belum Dilengkapi'
                            })
                        }
                    }
                    this.$refs['VFormKebutuhanPasien'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.aranrm_last_step = this.stepTab                
                            this.row.arj_tindakan_tambahan = this.row.arj_tindakan_tambahan ? ['Tidak Ada Tindakan'] : null
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }else if(this.stepTab == 3){
                    this.$refs['VFormKajianNyeri'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.aranrm_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }else if(this.stepTab == 4){
                    if(
                        (this.row.ap_usia <= 18 && this.row.arankrj_humpty_value > 11) ||
                        (this.row.ap_usia > 18 && this.row.ap_usia <= 60 && this.row.arankrj_humpty_value >= 25) || (this.row.ap_usia > 60 && this.row.arankrj_sydney_value >= 6)){
                        
                        if(this.isKosongChecklistTindakan){
                            return this.$swal({
                                icon: 'error',
                                text: 'Minimal Isi Setidaknya 1 Checklist Pengaman'
                            })
                        }
                        if(!(this.row.arankrj_jadwal_monitoring||[]).length){
                            return this.$swal({
                                icon: 'error',
                                text: 'Minimal Isi Setidaknya 1 Jadwal Monitoring'
                            })                   
                        }
                        if(!(this.row.arankrj_data_intervensi||[]).length){
                            return this.$swal({
                                icon: 'error',
                                text: 'Minimal Isi Setidaknya 1 Tindakan Intervensi'
                            })                   
                        }
                    }

                    this.$refs['VFormResikoJatuh'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.aranrm_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }else if(this.stepTab == 5){
                    this.$refs['VFormAsuhanKeperawatan'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.aranrm_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }else{
                    console.log("no action")
                }
                window.scrollTo(0,0)
            }
        },


        
        addMasterAlergi(){
            for(let i = 0; i < (this.row.arankaa_alergi||[]).length; i++){
                let newData = this.row.arankaa_alergi[i]
                if((newData.name||"").length && newData.jenis == "Obat"){
                    let dataPost = {
                        type : 'auto-save-riwayat',
                        name : newData.name
                    }  
                    Gen.apiRest(
                        "/do/"+this.modulePage,
                        {data:dataPost}, 
                        "POST"
                    )
                }
            }
        },
        doSubmit(){
            if(this.row.aranrm_is_active == 'N'){
                return this.$swal({
                    icon: 'error',
                    title: 'Data Telah tidak Aktif',
                    text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
                }).then(result => {
                    if (result.value) {
                        setTimeout(()=>{
                            let inv = []
                            let el = document.querySelectorAll('.label_error')
                            for(let i = 0; i < (el||[]).length; i++){
                                if(el[i].style.display !== 'none'){
                                    inv.push(el[i].id)
                                }
                            }
                            if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                        },500)
                    }
                })
            }   
            let inval = 0
            this.$refs['VFormAssesmentAwal'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 1
                    inval = inval + 1
                    this.row.aranrm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
            this.$refs['VFormKebutuhanPasien'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 2
                    inval = inval + 1
                    this.row.aranrm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                    
                }
            })
            this.$refs['VFormKajianNyeri'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 3
                    inval = inval + 1
                    this.row.aranrm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
            this.$refs['VFormResikoJatuh'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 4
                    inval = inval + 1
                    this.row.aranrm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
            this.$refs['VFormAsuhanKeperawatan'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 5
                    inval = inval + 1
                    this.row.aranrm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
            setTimeout(()=>{
                if(inval){
                    return this.$swal({
                        icon: 'error',
                        title: 'Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }
                if(!(this.diagnosa||[]).length){
                    return this.$swal({
                        icon: 'error',
                        title: 'Minimal harus ada 1 diagnosa'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }   

                let invalSiki = 0
                let invalSikiTab = []

                let invalSlki = 0
                let invalSlkiTab = []

                for(let i = 0; i < (this.diagnosa||[]).length; i++){
                    if(!(this.diagnosa[i]['siki']||[]).length){
                        invalSiki += 1
                        invalSikiTab.push(i+1)
                    }
                    if(!(this.diagnosa[i]['slki']||[]).length){
                        invalSlki += 1
                        invalSlkiTab.push(i+1)
                    }
                }
                
                if(invalSlki){
                    return this.$swal({
                        icon: 'error',
                        title: `SLKI Pada Tab Ke ${invalSlkiTab.join(', ')} Kosong`
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }   
                
                if(invalSiki){
                    return this.$swal({
                        icon: 'error',
                        title: `SIKI Pada Tab Ke ${invalSikiTab.join(', ')} Kosong`
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }   

                
                if(!inval){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        this.addMasterAlergi()
                        
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data'
                            data.diagnosa = this.diagnosa

                            this.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+this.modulePage,
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                }).then(result => {
                                    if (result.value) {
                                        if(this.user.levelId == 1){
                                            this.$router.push({ name: 'RanapPasienPerawatan'}).catch(()=>{})
                                        }else{
                                            this.$router.push({ name: 'RanapPasienPerawatan'}).catch(()=>{})
                                        }
                                    }
                                })
                            }).catch(err=>{
                                this.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                            
                            // 934?regId=1367
                        }
                    })
                }
            },100)
        },
    },
    mounted() {
        this.apiGet() 
        this.getPatientInfo()
        setTimeout(()=>{
            this.stepTab = this.row.aranrm_last_step||1
        },1500)

    },
    watch:{
        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
        diagnosa: {
            handler(v) {
                this.autoSaveDiagnosa(v)
            },
            deep: true
        },
    }
}

</script>